<!--
 * @Description: 导入价格体系时编辑地址和物料分类弹框
 * @Author: ChenXueLin
 * @Date: 2021-11-09 18:26:29
 * @LastEditTime: 2022-07-15 16:38:03
 * @LastEditors: ChenXueLin
-->
<template>
  <el-dialog
    v-dialogDrag
    title="价格体系"
    :visible.sync="visible"
    width="950px"
    :before-close="closeDialog"
    :close-on-click-modal="false"
    append-to-body
    :element-loading-background="loadingBackground"
    custom-class="add-concat-dialog"
    @open="openDialog"
  >
    <el-table
      :data="tableData"
      ref="analysisTable"
      border
      height="300px"
      highlight-current-row
    >
      <el-table-column
        v-for="(col, idx) in detailColumns"
        :label="col"
        :prop="col"
        width="160"
        header-align="center"
        :key="`${col}-${idx}`"
      >
        <template slot-scope="{ column, row }">
          <el-input v-model="row[column.label]" style="width:130px;"></el-input>
        </template>
      </el-table-column>
      <el-table-column
        fixed="right"
        align="center"
        label="操作"
        width="90"
        class-name="td-operate"
      >
        <template slot-scope="{ $index }">
          <el-button type="text" @click="deleteColumn($index)">
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>

    <div class="dialog-footer" slot="footer">
      <el-button class="cancel" @click="closeDialog">取消</el-button>
      <el-button type="primary" @click="confirm">确定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import base from "@/mixins/base";
export default {
  name: "uploadDialog",
  components: {},
  data() {
    return {
      tableData: []
    };
  },
  props: {
    uploadVisible: {
      type: Boolean,
      default: false
    },
    detailTableData: {
      type: Array,
      default: () => []
    },
    detailColumns: {
      type: Array,
      default: () => []
    }
  },
  mixins: [base],
  computed: {
    visible: {
      get() {
        return this.uploadVisible;
      },
      set(val) {
        this.$emit("update:uploadVisible", val);
      }
    }
  },
  watch: {},
  created() {},
  methods: {
    //打开弹框
    openDialog() {
      let tableData = _.cloneDeep(this.detailTableData);
      this.tableData = tableData;
    },
    //删除单行
    deleteColumn(index) {
      let tableData = _.cloneDeep(this.tableData);
      tableData.splice(index, 1);
      this.tableData = tableData;
    },
    //点击确定
    confirm() {},
    //点击取消
    closeDialog() {
      this.visible = false;
    }
  }
};
</script>
<style lang="scss" scoped>
/deep/.add-concat-dialog {
  .el-dialog__body {
    padding: 20px;
    .el-input {
      width: 220px;
    }
  }
}
</style>
